import React, { Fragment, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  Link,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AccountBalance,
  Place,
  Label,
  AccountCircle,
  Audiotrack,
  Videocam,
  Grass,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';
import { getFieldIdFromFormFields } from 'generic/utils/qesUtils';
import { documentPropType } from 'generic/core/qes/proptypes';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const useStyles = makeStyles((theme) => ({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
}));

const Document = ({
  activeBaseFields,
  disableTagsRefine,
  displayTitle,
  document,
  handleGetDocumentComplete,
  isCompleteDisplay,
  shrinkDocumentDisplay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mediaRef = useRef(null);
  const textRefs = useRef([]);
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const {
    title, linkout, date, status, thumb, snippet, textJson, source, htmlWithAnnotations, urlVideo, urlAudio,
  } = useDocumentFields(document);

  const { qesdocument } = document;

  const parseTextJson = useMemo(() => (JSON.parse(textJson)), [textJson]);

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  const handleTimeUpdate = () => {
    if (mediaRef.current) {
      const { currentTime } = mediaRef.current;
      // Trouver le texte associé au moment actuel
      const activeItemIndex = parseTextJson.findIndex((item, index) => {
        const nextItem = parseTextJson[index + 1];
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1 && textRefs.current[activeItemIndex]) {
        textRefs.current[activeItemIndex].scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleTranscriptClick = (time) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
      mediaRef.current.play();
    }
  };

  function formatTime(seconds) {
    const milliseconds = seconds * 1000;
    // Calcul des heures, minutes et secondes
    const heures = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const secondes = Math.floor((milliseconds % (1000 * 60)) / 1000);

    // Ajouter des zéros devant si nécessaire
    const heuresStr = heures.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondesStr = secondes.toString().padStart(2, '0');

    // Retourner le temps formaté
    return `${heuresStr}:${minutesStr}:${secondesStr}`;
  }

  return (
    <Box display="flex" flexDirection="column" overflow="auto" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          {!smallerThanMedium && thumb && (
            <Box mr={1} mb="2px" sx={{ float: 'left' }}>
              <ImgLoader
                src={thumb}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                className={classes.itemImage}
                skeletonClassName={classes.itemImage}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {date && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {date}
                </Typography>
              )}
              {!shrinkDocumentDisplay && status > 0 && (
                <Fragment>
                  {date && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                </Fragment>
              )}
              {source && (
                <Fragment>
                  {(status > 0 || date) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {source}
                  </Typography>
                </Fragment>
              )}
              {!shrinkDocumentDisplay && CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete' && linkout && (
                <Fragment>
                  {source && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {t('actions.open_in_new_tab')}
                    </Typography>
                  </Link>
                </Fragment>
              )}
              {!isCompleteDisplay && urlVideo && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Videocam fontSize="small" />
                </Fragment>
              )}
              {!isCompleteDisplay && urlAudio && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Audiotrack fontSize="small" />
                </Fragment>
              )}
            </Box>
          </Box>
          {!isCompleteDisplay && (
            <Box component="span" lineHeight="1.3rem">
              {snippet}
            </Box>
          )}
          {!shrinkDocumentDisplay && (
            <ExpandableTagBox
              disableRefine={disableTagsRefine}
              qesdocument={qesdocument}
              tags={[
                {
                  fieldName: 'QES_Company',
                  icon: AccountBalance,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_Company'),
                },
                {
                  fieldName: 'QES_Person',
                  icon: AccountCircle,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_Person'),
                },
                {
                  fieldName: 'QES_Location',
                  icon: Place,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_Location'),
                },
                {
                  fieldName: 'QES_ConceptCategorized',
                  icon: Label,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_ConceptCategorized'),
                },
                {
                  fieldName: 'QES_Stupefiants',
                  icon: Grass,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_Stupefiants'),
                },
                {
                  fieldName: 'QES_StupefiantsOriginal',
                  icon: Grass,
                  fieldId: +getFieldIdFromFormFields(activeBaseFields, 'QES_StupefiantsOriginal'),
                },
              ]}
            />
          )}
        </Box>
      </Box>
      {isCompleteDisplay && (
        <Fragment>
          {urlVideo && (
            <Card
              sx={{
                maxWidth: '500px',
                mt: 2,
                mb: 2,
                flexShrink: 0,
              }}
            >
              <CardMedia component="video" controls src={urlVideo} ref={mediaRef} />
            </Card>
          )}
          {urlAudio && (
            <CardMedia
              sx={{
                maxWidth: '500px',
                mt: 2,
                mb: 2,
                flexShrink: 0,
              }}
              component="audio"
              controls
              src={urlAudio}
              ref={mediaRef}
              onTimeUpdate={handleTimeUpdate}
            />
          )}
          <Box
            mt={2}
            whiteSpace="pre-wrap"
            lineHeight="30px"
            sx={{ clear: 'both' }}
            overflow="auto"
          >
            {htmlWithAnnotations ? (
              <div
                className="html-with-tags"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: htmlWithAnnotations }}
              />
            ) : (
              _.map(parseTextJson, (item, index) => (
                <Box
                  key={index}
                  mt={2}
                  ref={(el) => { textRefs.current[index] = el; }}
                >
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    mb={0.5}
                  >
                    <Box>
                      {t('document.time')}
                      <Box
                        component="span"
                        cursor="pointer"
                        sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                        onClick={() => handleTranscriptClick(item.start)}
                      >
                        {formatTime(item.start)}
                      </Box>
                    </Box>
                    <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                    <Box>
                      {t('document.speaker')}
                      {item.spkid}
                    </Box>
                  </Box>
                  <Box sx={{ '&::first-letter': { textTransform: 'uppercase' } }}>{item.sentence.trim()}</Box>
                </Box>
              ))
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

Document.propTypes = {
  activeBaseFields: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      interrogation: PropTypes.string,
    }),
  ),
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  document: documentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
  shrinkDocumentDisplay: PropTypes.bool,
};

Document.defaultProps = {
  activeBaseFields: [],
  disableTagsRefine: false,
  displayTitle: true,
  handleGetDocumentComplete: null,
  isCompleteDisplay: false,
  shrinkDocumentDisplay: false,
};

export default Document;
