export default {
  UPLOAD_FILE_SETTINGS: {
    ENABLE: true,
    BY_GED: true,
    BASE: 22801201,
  },

  DEFAULT_SEARCH_PER_BASE: {
    22801201: {
      F_2: '*',
    },
  },

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        COOCCURRENCES_FIELDS: [
          { name: 'Personnes', value: 'QES_Person.verbatim', active: true },
          { name: 'Sociétés', value: 'QES_Company.verbatim', active: true },
          { name: 'Concepts', value: 'QES_Concept.verbatim', active: true },
          { name: 'Stupéfiants', value: 'QES_Stupefiants.verbatim', active: true },
        ],
      },
    },
  },
};
