const fr = {
  fr: {
    translation: {
      document: {
        time: 'Temps : ',
        speaker: 'Intervenant : ',
      },
    },
  },
};

export default fr;
