import PropTypes from 'prop-types';

const stringOrArrayOfStrings = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

const qesdocumentPropType = PropTypes.shape({
  BASE_ID: stringOrArrayOfStrings.isRequired,
  idext: stringOrArrayOfStrings.isRequired,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
