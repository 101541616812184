const en = {
  en: {
    translation: {
      document: {
        time: 'Time : ',
        speaker: 'Speaker : ',
      },
    },
  },
};

export default en;
