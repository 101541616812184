import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import {
  generateLinkToResource, getDocumentBaseId, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

export default (document) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const bases = useSelector((state) => state.config.bases);
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = getValueOrFirstValueFromArray(qesdocument?.Text);
  const textJson = getValueOrFirstValueFromArray(qesdocument?.Text_json);
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.DATE_COLLECT);
  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);
  const attachedFile = getValueOrFirstValueFromArray(qesdocument?.File_audio);

  // dernières retouches avant le retour du résulat
  let thumb;
  if (thumbXml) {
    thumb = qesdocument?.IMAGE ? thumbXml : generateLinkToResource(docBaseId, thumbXml);
  }

  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg'];
  const audioExtensions = ['m4a', 'wav', 'mp3'];
  let urlVideo;
  let urlAudio;
  if (!_.isEmpty(attachedFile) && videoExtensions.includes(attachedFile.split('.').pop().toLowerCase())) {
    urlVideo = `${API_ENTRY_POINT}/../files/${attachedFile}`;
  }
  if (!_.isEmpty(attachedFile) && audioExtensions.includes(attachedFile.split('.').pop().toLowerCase())) {
    urlAudio = `${API_ENTRY_POINT}/../files/${attachedFile}`;
  }

  const title = titleXml || `[${t('document.title_undefined')}]`;
  const status = +statusXml;
  const date = format(datePubXml);
  const snippet = _.truncate(text, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  return {
    date,
    id,
    pdf,
    thumb,
    snippet,
    status,
    text,
    textJson,
    title,
    linkout,
    source,
    urlVideo,
    urlAudio,
  };
};
